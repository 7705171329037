<template>
  <div class="main-container">
    <div class="create-client-person-container">
      <div class="create-client-person-header">
        <div class="header-text">Create New Client Person</div>
        <div style="margin-left: auto">
          <a style="color: #000000" href="/list-companies">
            <CloseOutlined class="cross-icon" />
          </a>
        </div>
      </div>
      <a-form :ref="formRef">
        <div
          class="create-client-person"
          style="padding-left: 1rem; padding-right: 1rem"
        >
          <div class="create-client-person-form">
            <div class="form-sub-section">
              <div class="add-client-person-form-elements left-form-elements">
                <a-form-item v-bind="validateInfos.accountId">
                  <span class="input-label">Company Name</span
                  ><span class="required-input">*</span>
                  <a-select
                    show-search
                    class="select-input"
                    :options="company_name_options"
                    @search="getCompanyName"
                    v-model:value="modelRef.accountId"
                    placeholder="Select Company Name"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    @change="updateCompanyId"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="add-client-person-form-elements">
                <a-form-item>
                  <span class="input-label">Company ID</span>
                  <a-input
                    class="form-input"
                    disabled
                    placeholder="Company ID"
                    v-model:value="companyIdValue"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-client-person-form-elements left-form-elements">
                <a-form-item
                  v-bind="validateInfos.unitId"
                  style="margin-bottom: 0.5rem"
                >
                  <span class="input-label">Unit</span
                  ><span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    :options="unitTypeOptions"
                    placeholder="select"
                    v-model:value="modelRef.unitId"
                  >
                  </a-select>
                </a-form-item>
                <a-checkbox v-model:checked="clientPersonStatus"
                  >Set as active</a-checkbox
                >
              </div>
              <div class="add-client-person-form-elements">
                <a-form-item>
                  <span class="input-label">Title</span>
                  <a-select
                    class="select-input"
                    :options="titleOptions"
                    placeholder="select"
                    v-model:value="modelRef.title"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-client-person-form-elements left-form-elements">
                <a-form-item v-bind="validateInfos.firstName">
                  <span class="input-label">First Name</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.firstName"
                    @change="updateFullName"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-client-person-form-elements">
                <a-form-item v-bind="validateInfos.lastName">
                  <span class="input-label">Last Name</span
                  ><span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.lastName"
                    @change="updateFullName"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-client-person-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Full Name</span>
                  <a-input
                    disabled
                    class="form-input"
                    v-model:value="fullNameValue"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-client-person-form-elements">
                <a-form-item>
                  <span class="input-label">DOB</span>
                  <a-date-picker
                    :format="dateFormat"
                    class="date-input"
                    style="border-radius: 15px !important; margin-top: 0.75rem"
                    v-model:value="modelRef.dob"
                  ></a-date-picker>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-client-person-form-elements left-form-elements">
                <a-form-item v-bind="validateInfos.position">
                  <span class="input-label">Position</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.position"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-client-person-form-elements">
                <a-form-item v-bind="validateInfos.departmentId">
                  <span class="input-label">Department</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    :options="departmentOptions"
                    v-model:value="modelRef.departmentId"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="add-client-person-form-elements left-form-elements multi-elements"
              >
                <a-form-item v-bind="validateInfos.mobile">
                  <span class="input-label">Mobile</span>
                  <span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    type="number"
                    v-model:value="modelRef.mobile"
                  ></a-input>
                </a-form-item>
                <a-form-item>
                  <span class="input-label">Alternate Phone</span>
                  <a-input
                    class="form-input"
                    type="number"
                    v-model:value="modelRef.alternatePhone"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-client-person-form-elements">
                <a-form-item v-bind="validateInfos.city">
                  <span class="input-label">Location(City)</span>
                  <span class="required-input">*</span>
                  <a-select
                    show-search
                    class="select-input"
                    :options="cityOptions"
                    v-model:value="modelRef.address.city"
                    @search="getCities"
                    placeholder="Select City"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="add-client-person-form-elements left-form-elements multi-elements"
              >
                <a-form-item v-bind="validateInfos.email">
                  <span class="input-label">Email</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.email"
                  ></a-input>
                </a-form-item>
                <a-form-item v-bind="validateInfos.alternateEmail">
                  <span class="input-label">Alternate Email</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.alternateEmail"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-client-person-form-elements multi-elements">
                <a-form-item>
                  <span class="input-label">Birthday Bouquet</span>
                  <a-select
                    class="select-input"
                    :options="binaryOptions"
                    v-model:value="modelRef.birthBouquet"
                  ></a-select>
                </a-form-item>
                <a-form-item>
                  <span class="input-label">Newsletter</span>
                  <a-select
                    class="select-input"
                    :options="binaryOptions"
                    v-model:value="modelRef.newsLetter"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="add-client-person-form-elements left-form-elements multi-elements"
                style="margin-right: 0"
              >
                <a-form-item style="flex-basis: 21%">
                  <span class="input-label">Address Type</span>
                  <a-select
                    class="select-input"
                    :options="addressTypeOptions"
                    v-model:value="modelRef.address.type"
                  ></a-select>
                </a-form-item>
                <a-form-item style="flex-basis: 77%; padding-left: 1rem">
                  <span class="input-label">Address</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.address.street"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="add-comments-section">
              <div class="icon-block">
                <a-avatar
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                />
              </div>
              <a-textarea
                class="form-text-area"
                v-model:value="clientPersonTextArea"
                :autoSize="{ minRows: 4, maxRows: 6 }"
              />
            </div>
            <div class="form-sub-section">
              <div class="add-client-person-horizontal-elements">
                <MinusOutlined
                  class="icon-style"
                  style="margin-right: 0.5rem; margin-top: 3rem"
                />
                <a-form-item style="flex-basis: 32%; padding-right: 1rem">
                  <span class="input-label">RM Status</span>
                  <a-select
                    show-search
                    class="select-input"
                    :options="cicOptions"
                    @search="getCICListing"
                    v-model:value="rmStatusObject.cicValue"
                    placeholder="Select CIC"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                  ></a-select>
                </a-form-item>
                <a-form-item
                  style="
                    margin-top: 1.3rem;
                    flex-basis: 32%;
                    padding-right: 1rem;
                  "
                >
                  <a-select
                    class="select-input"
                    placeholder="Select RM Status"
                    :options="rmStatusOptions"
                    v-model:value="rmStatusObject.rmStatusValue"
                  ></a-select>
                </a-form-item>
                <a-form-item style="margin-top: 1.3rem; flex-basis: 32%">
                  <a-select
                    class="select-input"
                    placeholder="Select Priority"
                    :options="priorityOptions"
                    v-model:value="rmStatusObject.priorityValue"
                  ></a-select>
                </a-form-item>
                <PlusOutlined
                  class="icon-style"
                  style="margin-top: 3rem"
                  @click="addRmStatus"
                />
              </div>
            </div>
            <div class="added-details-list">
              <a-table
                :columns="RMStatusColumns"
                :data-source="rmStatusObject.rmStatusList"
                :pagination="false"
                :row-selection="RMStatusRowSelection"
              >
              <template #rmDelete="{ record }">
                <a-popconfirm
                  title="Are you sure delete you want to delete?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="confirmRmDelete(record.key)"
                  @cancel="cancel"
                >
                  <DeleteOutlined style="cursor: pointer" />
                </a-popconfirm>
              </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="form-action-btns">
          <a-button type="link" class="clear-button" href="/list-companies"
            >Exit</a-button
          >
          <a-button class="create-client-person-btn" @click="onSubmit">
            Create Client Person
          </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import dayjs from "dayjs";
import { Form, message } from "ant-design-vue";
import {
  PlusOutlined,
  MinusOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import Services from "../../../services/apis";

const useForm = Form.useForm;
let fullNameValue = ref("");
let rmStatusObject = reactive({
  cicValue: "",
  rmStatusValue: "",
  priorityValue: "",
  rmStatusList: [],
});

const RMStatusColumns = [
  {
    key: "cicLabel",
    dataIndex: "cicLabel",
    title: "CIC",
    colSpan: 1,
    textalign: "left",
  },
  {
    key: "status",
    dataIndex: "status",
    title: "RM Status",
    colSpan: 1,
    textalign: "center",
  },
  {
    key: "priority",
    dataIndex: "priority",
    title: "Priority",
  },
  {
    title: "",
    dataIndex: "rmDelete",
    key: "rmDelete",
    align: "right",
    slots: { customRender: "rmDelete" },
    width: 150,
  },
];

const RMStatusRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

export default defineComponent({
  setup() {
    const dateFormat = "YYYY/MM/DD";
    const router = useRouter();
    const formRef = ref();
    const rulesRef = reactive({
      accountId: [
        {
          required: true,
          message: "Please select a company name",
        },
      ],
      unitId: [
        {
          required: true,
          message: "Please select a Unit Type",
        },
      ],
      firstName: [
        {
          required: true,
          message: "Please enter First Name",
        },
      ],
      lastName: [
        {
          required: true,
          message: "Please enter Last Name",
        },
      ],
      position: [
        {
          required: true,
          message: "Please enter Position",
        },
      ],
      departmentId: [
        {
          required: true,
          message: "Please select a Department",
        },
      ],
      mobile: [
        {
          required: true,
          message: "Please enter valid Mobile #",
        },
      ],
      email: [
        {
          required: false,
          type: "email",
          message: "Please enter valid email",
        },
      ],
      alternateEmail: [
        {
          required: false,
          type: "email",
          message: "Please enter valid email",
        },
      ],
      city: [
        {
          required: true,
          message: "Please select a city",
        },
      ],
    });
    let modelRef = reactive({
      accountId: ref(""),
      unitId: ref(""),
      title: ref(""),
      firstName: "",
      lastName: "",
      dob: "",
      position: "",
      departmentId: ref(""),
      mobile: "",
      alternatePhone: "",
      email: "",
      alternateEmail: "",
      birthBouquet: ref(""),
      newsLetter: ref(""),
      address: {
        type: ref(""),
        street: "",
        city: "",
      },
      rm: [
        {
          cic: ref(""),
          status: ref(""),
          priority: ref(""),
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );
    const companyIdValue = ref("");

    const updateFullName = () => {
      fullNameValue.value = modelRef.firstName + " " + modelRef.lastName;
    };

    const updateCompanyId = () => {
      let companyOptions = company_name_options.value;
      let companyObject = companyOptions.filter((company) => {
        if (company.value === modelRef.accountId) {
          return company;
        }
      });
      companyIdValue.value = companyObject[0].companyId;
    };

    let company_name_options = ref([]);
    let unitTypeOptions = ref([]);
    let titleOptions = ref([]);
    let departmentOptions = ref([]);
    let cityOptions = ref([]);
    let binaryOptions = ref([
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ]);
    let addressTypeOptions = ref([
      { value: "PERMANENT", label: "Permanent" },
      { value: "COMMUNICATION", label: "Communication" },
    ]);
    let clientPersonTextArea = ref("");
    let cicOptions = ref([]);
    let rmStatusOptions = ref([]);
    let priorityOptions = ref([
      { value: "LOW", label: "Low" },
      { value: "MEDIUM", label: "Medium" },
      { value: "HIGH", label: "High" },
    ]);
    // Company names listing for Company name Select
    const getCompanyName = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getAccountListing(payload)
        .then((response) => {
          const { data } = response;
          let tempData = [];
          data.map((x) => {
            tempData.push({
              value: x.account.id,
              label: x.account.name,
              companyId: x.account.companyId,
            });
          });
          Object.assign(company_name_options.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    // City listing for location I/p
    const getCities = (value) => {
      Services.cityListing({ q: value })
        .then((response) => {
          const { data } = response;
          cityOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    // User listing api call for cicOptions
    const getCICListing = (value) => {
      let payload = {
        q: "",
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        type: "MEMBER",
      };

      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          cicOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    function displayLongDate(value) {
      return dayjs(value).format("YYYY-MM-DD");
    }

    // OnMounted
    onMounted(() => {
      getCompanyName();
      getCities();
      getCICListing();

      // Unit Listing for unit Type options
      Services.resumeListing({ types: ["UNIT"] })
        .then((response) => {
          const { data } = response;
          unitTypeOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // Title listing for title field
      Services.genderListing({ types: ["TITLE"] })
        .then((response) => {
          const { data } = response;
          titleOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // Depart listing for department select i/p
      Services.getDepartmentsDetail()
        .then((response) => {
          const { data } = response;
          departmentOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // RM Status Listing
      Services.getRmStatusDetail({ types: ["RM"] })
        .then((response) => {
          const { data } = response;
          rmStatusOptions.value = data.data.map(
            ({ name: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    });

    const addRmStatus = () => {
      let cicValue = cicOptions.value.filter(
        (cic) => cic.value === rmStatusObject.cicValue
      );
      let rmStatusList = rmStatusObject.rmStatusList;
      let id = rmStatusList.length + 1;
      let rmStatus = {
        key: id,
        cicLabel: cicValue[0].label,
        cic: cicValue[0].value,
        status: rmStatusObject.rmStatusValue,
        priority: rmStatusObject.priorityValue,
      };
      rmStatusList.push(rmStatus);

      Object.assign(modelRef.rm, rmStatusList);
      Object.assign(rmStatusObject, {
        key:id,
        cicValue: "",
        rmStatusValue: "",
        priorityValue: "",
        rmStatusList,
      });
    };
    const confirmRmDelete = (key) => {
      rmStatusObject.rmStatusList =
        rmStatusObject.rmStatusList.filter(
          (item) => item.key !== key
        );
    };
    const onSubmit = () => {
      // modelRef.rm.shift();
      validate()
        .then((result) => {
          if (modelRef.title === "") {
            delete modelRef.title;
          }
          if (modelRef.alternatePhone === "") {
            delete modelRef.alternatePhone;
          }
          if (modelRef.email === "") {
            delete modelRef.email;
          }
          if (modelRef.alternateEmail === "") {
            delete modelRef.alternateEmail;
          }
          if (modelRef.birthBouquet === "") {
            delete modelRef.birthBouquet;
          }
          if (modelRef.newsLetter === "") {
            delete modelRef.newsLetter;
          }
          if (modelRef.dob) {
            modelRef.dob = displayLongDate(modelRef.dob);
          }
          Services.createClientPerson(modelRef).then((response) => {
            const { data } = response;
            console.log("data :>> ", data);
            message.success("Client Person Created successfully");
            router.push("/list-companies");
          });
        })
        .catch((err) => {
          // console.log("modelRef.dob :>> ", displayLongDate(modelRef.dob));
          console.log("error", err);
        });
    };

    return {
      dateFormat,
      //Form Object
      formRef,
      rulesRef,
      validate,
      confirmRmDelete,
      validateInfos,
      resetFields,
      // Table Row Selection Variables
      RMStatusRowSelection,
      // Table Column Definitions
      RMStatusColumns,
      // Select Option Variables
      company_name_options,
      unitTypeOptions,
      titleOptions,
      departmentOptions,
      cityOptions,
      cicOptions,
      rmStatusOptions,
      binaryOptions,
      addressTypeOptions,
      priorityOptions,

      // Select Input variables
      modelRef,
      fullNameValue,
      companyIdValue,
      // Funtions
      addRmStatus,
      getCompanyName,
      getCities,
      getCICListing,
      updateFullName,
      updateCompanyId,
      displayLongDate,
      onSubmit,
      // Table data objects
      rmStatusObject,
      // Text Area Variabbles
      clientPersonTextArea,

      // Set as Active CheckBox value
      clientPersonStatus: ref(true),
    };
  },
  components: {
    PlusOutlined,
    MinusOutlined,
    CloseOutlined,
    DeleteOutlined,
  },
});
</script>

<style lang="scss">
@import "../styles/createClientPerson.scss";
</style>
